<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffReport.assign_application_status_report')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row class="mt-4">
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Association Name" vid="association_info_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="association_info_id"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.application_type')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.application_type_id"
                  :options="applicationTypeList"
                  id="association_info_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Association Name" vid="association_info_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="association_info_id"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffReport.application_status')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.application_status"
                  :options="applicationTypeListOld"
                  id="association_info_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-for="designation_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('globalTrans.designation')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.designation_id"
                  :options="designationList"
                  @change="getUser"
                  id="designation_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Officer Name" vid="receiver_id" rules="required|min_value:1">
              <b-form-group
                label-for="receiver_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('exportTrophyCircular.employee') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="search.user_id"
                :options="userList"
                id="receiver_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Arrival Date From" vid="arrival_date_from" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="arrival_date_from">
                  <template v-slot:label>
                    {{ $t('externalTradeFair.date_from') }}
                  </template>
                  <date-picker
                    id="arrival_date_from"
                    v-model="search.date_from"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Arrival Date From" vid="arrival_date_from" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="arrival_date_from">
                  <template v-slot:label>
                    {{ $t('externalTradeFair.date_to') }}
                  </template>
                  <date-picker
                    id="arrival_date_from"
                    v-model="search.date_to"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
        </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <body-card style="overflow-x: clip;">
      <b-overlay :show="loading">
    <div>
          <b-row class="text-right mt-2">
          <b-col>
        <b-button @click="pdfExport" class="btn mx-2 btn-success btn-md">
           <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
        </b-button>
          </b-col>
        </b-row>
        <div class="table-wrapper table-responsive" >
           <ReportHeading />
          <table class="table table-striped table-hover table-bordered">
          <tr>
            <th colspan="15" style="text-align: center;">{{ $t('tradeTariffReport.assign_application_status_report') }}</th>
          </tr>
          <tr>
            <th>{{$t('globalTrans.sl_no')}}</th>
            <th>{{$t('globalTrans.application_id')}}</th>
            <th>{{$t('globalTrans.application_type')}}</th>
            <th>{{$t('tradeTariffReport.assigned_officer')}}</th>
            <th>{{$t('tradeTariffReport.assigned_date')}}</th>
            <th>{{$t('tradeTariffReport.application_status')}}</th>
          </tr>
            <template v-if="itemList && itemList.length > 0">
            <tr v-for="(item,index) in itemList" :key="index">
              <td>{{ $n(index + 1) }}</td>
              <td>{{ item.auto_id }}</td>
              <td>{{ currentLocale == 'en' ? getApplicationType(item.application_type_id)[0].text_en : getApplicationType(item.application_type_id)[0].text_bn }}</td>
              <td>{{ currentLocale == 'en' ? item.name : item.name_bn }}</td>
              <td><span>{{ item.updated_at | dateFormat }}</span></td>
              <td>{{ currentLocale == 'en' ? getStatus(item.application_status)[0].text_en : getStatus(item.application_status)[0].text_bn }}</td>
            </tr>
            </template>
             <template v-else>
                <tr>
                    <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                </tr>
            </template>
        </table>
        </div>
    </div>
    </b-overlay>
    </body-card>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { AssignApplicationStatusReportList, AssignApplicationStatusReportPdf, userListByDesignationApi } from '../../api/routes'
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import { dateFormatBn, dateFormatEn, dateFormat } from '@/utils/fliter'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      dateFormat: dateFormat,
      dateFormatEn: dateFormatEn,
      dateFormatBn: dateFormatBn,
      userList: [],
      search: {
      fiscal_year_id: 0,
      report_type_id: 0,
      council_info_id: 0,
      association_info_id: 0,
      fiscal_year_to_id: 0,
      is_monitor: 1,
      application_type_id: 0,
      application_status: 0,
      designation_id: 0,
      date_from: '',
      date_to: '',
      user_id: 0
      },
      itemListGroup: [],
      itemList: [],
      associationList: [],
      show: false,
      loading: false
    }
  },
  created () {
  },
  watch: {
     'search.council_info_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
       this.associationList = this.$store.state.EBizManagementSystemService.commonObj.associationInfoList.filter(item => item.status === 1 && item.council_info_id === newVal)
      }
    }
  },
  computed: {
    applicationTypeListOld: function () {
      if (this.$i18n.locale === 'en') {
      return [
          {
              value: 1,
              text: 'application list',
              text_en: 'application list',
              text_bn: 'আবেদন তালিকা',
              status: 1
          },
          {
              value: 2,
              text: 'received',
              text_en: 'received',
              text_bn: 'প্রাপ্ত',
              status: 1
          },
          {
              value: 3,
              text: 'assigned',
              text_en: 'assigned',
              text_bn: 'বরাদ্দ',
              status: 1
          },
          {
              value: 4,
              text: 'approve',
              text_en: 'approve',
              text_bn: 'অনুমোদন',
              status: 1
          }
      ]
      } else {
              return [
          {
              value: 1,
              text: 'আবেদন তালিকা',
              text_en: 'application list',
              text_bn: 'আবেদন তালিকা',
              status: 1
          },
          {
              value: 2,
              text: 'প্রাপ্ত',
              text_en: 'received',
              text_bn: 'প্রাপ্ত',
              status: 1
          },
          {
              value: 3,
              text: 'বরাদ্দ',
              text_en: 'assigned',
              text_bn: 'বরাদ্দ',
              status: 1
          },
          {
              value: 4,
              text: 'অনুমোদন',
              text_en: 'approve',
              text_bn: 'অনুমোদন',
              status: 1
          }
      ]
      }
      },
    applicationTypeList: function () {
      return this.$store.state.TradeTariffService.commonObj.applicationTypeList.filter(item => item.status === 1)
    },
    designationList: function () {
      return this.$store.state.CommonService.commonObj.designationList.filter(item => item.org_id === 4)
    },
    currentLocale () {
        return this.$i18n.locale
    },
     councilInfoList () {
      return this.$store.state.EBizManagementSystemService.commonObj.councilInfoList.filter(item => item.status === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  methods: {
    getUser () {
      this.getUserList(this.search.designation_id)
    },
    getUserList (designationId) {
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, `${userListByDesignationApi}/${designationId}`).then(response => {
        const userList = response.filter(obj => obj.value !== this.$store.state.Auth.authUser.user_id).map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.value,
            text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          })
        })
        this.userList = userList
        this.userLoading = false
        return userList
      })
    },
    async searchData () {
      this.loadData = true
      this.loading = true
      let result = null
      const params = {
        search: this.search,
        officerName: this.userList
      }
      result = await RestApi.postData(tradeTariffServiceBaseUrl, AssignApplicationStatusReportList, params)
      if (result.success) {
          this.itemList = result.data.map((item, index) => {
             return Object.assign({}, item, { serial: index, date: dateFormatEn('') + dateFormatBn('') })
          })
          var self = this
          this.itemList.map(elementOne => {
            elementOne.application_type_en = self.getApplicationType(elementOne.application_type_id)[0].text_en
            elementOne.application_type_bn = self.getApplicationType(elementOne.application_type_id)[0].text_en
            elementOne.status_en = self.getStatus(elementOne.application_status)[0].text_en
            elementOne.status_bn = self.getStatus(elementOne.application_status)[0].text_bn
            elementOne.application_date_bn = self.dateFormatBn(elementOne.created_at)
            elementOne.application_date_en = self.dateFormatEn(elementOne.created_at)
            elementOne.name = self.userList.find(elementO => elementO.value === elementOne.user_idd).text_en
            elementOne.name_bn = self.userList.find(elementO => elementO.value === elementOne.user_idd).text_bn
          })
          this.loadData = false
          this.show = true
          this.loading = false
      } else {
          this.loadData = false
          this.loading = false
      }
  },
  getApplicationType (id) {
  return this.$store.state.TradeTariffService.commonObj.applicationTypeList.filter(item => item.value === id)
  },
  getStatus (id) {
      var applicationType = [
          {
              value: 1,
              text: 'application list',
              text_en: 'application list',
              text_bn: 'আবেদন তালিকা',
              status: 1
          },
          {
              value: 2,
              text: 'received',
              text_en: 'received',
              text_bn: 'প্রাপ্ত',
              status: 1
          },
          {
              value: 3,
              text: 'assigned',
              text_en: 'assigned',
              text_bn: 'বরাদ্দ',
              status: 1
          },
          {
              value: 4,
              text: 'approve',
              text_en: 'approve',
              text_bn: 'অনুমোদন',
              status: 1
          }
      ]
      return applicationType.filter(item => item.value === id)
  },
  getFiscalYear (id) {
    const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
    if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    } else {
        return ''
    }
  },
  async pdfExport () {
       this.loading = true
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7, list: '' })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, AssignApplicationStatusReportPdf, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.loading = false
    }
  }
}
</script>
<style>
/* @import '../../style.css' */
</style>
